module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Parallel Employee Benefits","short_name":"Parallel Employee Benefits","start_url":"/","background_color":"#fff","theme_color":"#363636","display":"standalone","icon":"static/logos/parallel-logo-mobile.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
